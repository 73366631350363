<template>
    <div>
        <LandHeader />
        <BreadCrumb />
        <div class="center land-box land-info">
            <div class="land-info__header">
                <el-row :gutter="20">
                  <el-col :span="18" style="background-color: #f7f8fa">
                    <el-row :gutter="20" style="margin-bottom: 10px">
                      <el-col :span="12">
                        <h1 style=" margin-top: 10px;margin-left: 0px; padding-left: 10px;">{{ landInfo.title }}</h1>
                      </el-col>
                      <el-col :span="12">
                        <p class="land_info__item" style="padding-top: 5px">
                    <span style="color: black;margin-right: 20px">
                         倒计时:
                    </span>
                          <span   v-if="!isAuctioning && auctionInfo.transactionStatus !== 'finish'">
                         <timeCounter :endTime="auctionInfo.applyEndTime"   endText="已经结束" />
                    </span>
                          <span v-else-if="auctionInfo.transactionStatus === 'finish'" style="font-size: 23px;color:#1e88fb" >
                         已截止
                    </span>
                          <span v-else >
                         <timeCounter :endTime="auctionInfo.auctionEndTime"   endText="已经结束" />
                    </span>
                        </p>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <div class="exhibitionimg" style="width: 100%; height: 100%">
                          <div class="toplargebox">
                            <div class="bigimg">
                              <el-image class="el-image" :src="bigimgurl ?bigimgurl+'?w=600': landPics[0]+'?w=600'" fit="cover" style="width: 470px; height: 350px">
                              </el-image>
                            </div>
                          </div>
                          <div class="bottombox">
                            <ul>
                              <li v-for="(item, i) in landPics"  :key="i" >
                                <img :src="item+'?w=100'" fit="cover" @click="exhibitionimg($event)" style="width: 100%;height: 100%" />
                              </li>
                            </ul>
                          </div>

                          <div>
                            <el-row :gutter="20">
                              <el-col :span="6">
                                <el-button type="text" style="position:relative; text-align:left;margin-left: 20px;width: 60px" :icon="haveFavorite?'el-icon-star-on' : 'el-icon-star-off'"
                                                                class="fav-btn" @click="addFavorite">{{ haveFavorite?'取消收藏':'收藏' }}
                                 </el-button>
                              </el-col>
                              <el-col :span="6">
<!--                                <div style="width: 100%;height: 40px;text-align: center" v-if="auctionInfo.viewCount > 0">
                                  <i class="el-icon-view" style="margin-top: 11px;font-size: 13px"></i>
                                  <span style="font-size: 13px"> {{auctionInfo.viewCount}}次围观</span>
                                </div>-->
                              </el-col>
                              <el-col :span="6">
                                <div style="width: 100%;height: 40px;text-align: center" v-if="landInfo.vr > 0">
                                  <i class="el-icon-view" style="margin-top: 11px;font-size: 13px"></i>
                                  <span style="font-size: 13px"><RouterLink :to="'/link?url='+landInfo.vr ">{{ landInfo.title }}</RouterLink></span>
                                </div>
                              </el-col>
                            </el-row>
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="12">

                          <el-col>
                            <div class="price-panel">
                              <span class="label" style="color: black" v-if="auctionInfo.transactionStatus !== 'finish'">当前拍价：</span>
                              <span class="label" style="color: black" v-else>成交价：</span>
                              <span class="price-text">{{ auctionInfo.currentPrice ? auctionInfo.currentPrice :auctionInfo.startPrice }}万元</span>
                            </div>
                            <div  style="padding:30px 0 0 35px;"  v-if="auctionInfo.transactionStatus === 'finish'">
                              <el-button type="info" style="width: 150px;" >已成交</el-button>
                            </div>

                            <div  style="padding:30px 0 0 35px;"   v-if="auctionInfo.transactionStatus != 'finish'  && !isNormal  && isEnd">
                              <el-button type="info" style="width: 150px;" >竞价已结束</el-button>
                            </div>

                            <div class="mt25 mb25" style="padding:30px 0 0 35px;"  v-if="!isEnd">
                              <el-button type="info" plain round style="width:233px" v-if="auctionInfo.currentBuyerInfo && auctionInfo.currentBuyerInfo.auditStatus ===1">报名审核中</el-button>
<!--                              <el-button type="info" plain round style="width:233px" v-if="auctionInfo.currentBuyerInfo && auctionInfo.currentBuyerInfo.auditStatus ===3">审核拒绝</el-button>-->
                              <el-button type="danger"
                                         v-if="auctionInfo.biddingStatus ===1 && isNormal"
                                         style="background:red;width: 150px;" @click="applyAuction">报名</el-button>
                              <el-button type="danger"
                                         v-if="auctionInfo.biddingStatus ===3 && isAuctioning "
                                         style="background:red;width: 150px;" @click="bidPrice">出价</el-button>
                              <el-button type="danger" plain round style="width:233px"  v-if="!isAuctioning && auctionInfo.currentBuyerInfo && auctionInfo.currentBuyerInfo.auditStatus ===2">报名成功</el-button>
                              <el-button type="danger" plain round style="width:233px"   v-if="isNormal && auctionInfo.currentBuyerInfo && auctionInfo.currentBuyerInfo.auditStatus ===3" @click="applyAuction">再次报名</el-button>

                            </div>

                            <p  class="land_info__item" style="margin:50px 0 10px 0; ">
                              <i class="el-icon-warning" style="color: red;font-size: 20px"></i>
                              <span style="color: red;font-size: 14px;padding-top: 2px"> 成交后,需要向拍卖平台另付{{ auctionInfo.softwareUsageFee }}%的软件使用费</span>
                            </p>
                            <p  class="land_info__item" style="margin:10px 0 ">
                              <i class="el-icon-warning" style="color: red;font-size: 20px;"></i>
                                <span style="color: red;font-size: 14px;padding-top: 2px"> 成交后,需要向拍卖企业另付{{ auctionInfo.commissionRate }}%的佣金</span>
                            </p>
                          </el-col>
                        <el-col>
                          <el-descriptions title="" :column="2" class="mt15" >
                            <el-descriptions-item label="起拍价" label-class-name="my-content">{{ auctionInfo.startPrice }}万元</el-descriptions-item>
                            <el-descriptions-item label="保证金">{{ auctionInfo.deposit }}万元</el-descriptions-item>
                            <el-descriptions-item label="加价幅度" label-class-name="my-content" :contentStyle="{'color': 'red'}">{{ auctionInfo.rise }}万元</el-descriptions-item>
                            <el-descriptions-item label="评估价">{{ auctionInfo.assessedPrice }}万元</el-descriptions-item>
                            <el-descriptions-item label="保留价" label-class-name="my-content">{{ auctionInfo.reservePrice }}万元</el-descriptions-item>
<!--                            <el-descriptions-item label="优先购买权人">{{ auctionInfo.priorityPurchaser }}</el-descriptions-item>-->
                            <el-descriptions-item label="限时竞价时间">{{ auctionInfo.limitedBiddingTime }}</el-descriptions-item>
                            <el-descriptions-item label="自由竞价时间" label-class-name="my-content">{{ auctionInfo.freeBiddingTime }}</el-descriptions-item>

                          </el-descriptions>
                          <el-descriptions title="" :column="1">
                            <el-descriptions-item label="报名限制" label-class-name="my-content">{{ auctionInfo.registrationLimit ? auctionInfo.registrationLimit : '无' }}</el-descriptions-item>
<!--                            <el-descriptions-item label="所在地" label-class-name="my-content">{{ auctionInfo.location }}</el-descriptions-item>-->
                          </el-descriptions>
                        </el-col>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="6" >
                    <el-tabs type="border-card" value="auctioneer_msg" :stretch="true">
                      <el-tab-pane label="拍卖师发言"  name="auctioneer_msg">
                        <div  style="margin-top: 10px"  v-for="(speakItem, index) in speaks.records" :key="index" >
                          <span style="color: purple">拍卖会</span>
                          <span style="font-size: 11px"> {{speakItem.createTime}}</span>
                          <p style="margin-top: 3px"> <span style="font-size: 14px">{{speakItem.content}}</span></p>
                        </div>

                      </el-tab-pane>
                      <el-tab-pane label="拍卖师信息" name='auctioneer_info'>
                        <el-descriptions class="margin-top" title="" :column="1"  border>
                         <el-descriptions-item  v-if="auctionInfo.paimaishiInfo">
                            <template slot="label">
                              <i class="el-icon-user"></i>
                              姓名
                            </template>
                            {{ auctionInfo.paimaishiInfo.name }}
                          </el-descriptions-item>
                          <el-descriptions-item  v-if="auctionInfo.paimaishiInfo">
                            <template slot="label">
                              <i class="el-icon-mobile-phone"></i>
                              手机号
                            </template>
                            {{ auctionInfo.paimaishiInfo.mobile }}
                          </el-descriptions-item>
                          <el-descriptions-item  v-if=" auctionInfo.paimaishiInfo">
                            <template slot="label">
                              <i class="el-icon-office-building"></i>
                              公司
                            </template>
                            {{ auctionInfo.paimaishiInfo.companyName }}
                          </el-descriptions-item>
                            <el-descriptions-item  v-if=" auctionInfo.paimaishiInfo">
                            <template slot="label">
                              <i class="el-icon-s-finance"></i>
                              证书
                            </template>
                            {{ auctionInfo.paimaishiInfo.certNo }}
                          </el-descriptions-item>

                        </el-descriptions>
                      </el-tab-pane>

                    </el-tabs>
                  </el-col>
                </el-row>
            </div>
          <div id="info" style="padding-top: 5px; padding-left:  5px ;">
            <el-descriptions title="土地信息" :column="2" border style="padding-left: 10px;padding-top: 10px">
              <el-descriptions-item label="土地名称" label-align="right" align="center" width="150px" span="2">{{ landInfo.title }}</el-descriptions-item>
              <el-descriptions-item label="资源编号" label-align="right" align="center" width="150px" span="2">{{ auctionInfo.resourceNo }}</el-descriptions-item>
              <el-descriptions-item label="土地描述" span="3"><div v-html="landInfo.desc"></div></el-descriptions-item>
              <el-descriptions-item label="报名时间开始" label-align="right" align="center" width="150px">{{ dateStr(auctionInfo.applyStartTime)}}</el-descriptions-item>
              <el-descriptions-item label="报名时间截止" label-align="right" align="center" width="150px">{{ dateStr(auctionInfo.applyEndTime)}}</el-descriptions-item>
              <el-descriptions-item label="拍卖时间开始" label-align="right" align="center" width="150px">{{ dateStr(auctionInfo.auctionStartTime)}}</el-descriptions-item>
              <el-descriptions-item label="拍卖时间截止" label-align="right" align="center" width="150px">{{ dateStr(auctionInfo.auctionEndTime)}}</el-descriptions-item>
              <!--                        <el-descriptions-item label="保证金缴纳时间开始" label-align="right" align="center" width="150px">{{ dateStr(auctionInfo.depositStartTime)}}</el-descriptions-item>-->
              <!--                        <el-descriptions-item label="保证金缴纳时间截止" label-align="right" align="center" width="150px">{{ dateStr(auctionInfo.depositEndTime)}}</el-descriptions-item>-->
              <el-descriptions-item label="竞买保证金（RMB）" label-align="right" align="center" width="150px">{{ auctionInfo.deposit }} 万元</el-descriptions-item>
              <el-descriptions-item label="起始价" label-align="right" align="center" width="150px">{{ auctionInfo.startPrice }} 万元</el-descriptions-item>
              <el-descriptions-item label="竞价增价幅度" label-align="right" align="center" width="150px">{{ auctionInfo.rise }} 万元</el-descriptions-item>


              <el-descriptions-item label="土地用途" label-align="right" align="center" width="150px">{{ findLabel(demand_purpose,landInfo.landPurpose) }}
                {{landInfo.landPurpose=='other_land'?' - '+landInfo.subLandPurpose:''}}
                 </el-descriptions-item>
              <el-descriptions-item label="交易方式" label-align="right" align="center" width="150px">{{ findLabel(transaction_way,landInfo.transactionWay) }}</el-descriptions-item>

              <el-descriptions-item label="所属行政区" label-align="right" align="center">{{ findLabel(land_area,landInfo.regionId) }}</el-descriptions-item>
              <!--                        <el-descriptions-item label="权属人性质" label-align="right" align="center">{{ findLabel(land_owner_type,landInfo.ownerType) }}</el-descriptions-item>-->
              <el-descriptions-item label="土地权属单位" label-align="right" align="center">{{landInfo.ownerOrg}}</el-descriptions-item>
              <el-descriptions-item label="权证类型" label-align="right" align="center">{{findLabel(land_license_type,landInfo.licenseNoType)}}</el-descriptions-item>
              <!--                        <el-descriptions-item label="权证编号" label-align="right" align="center">{{landInfo.licenseNo}}</el-descriptions-item>-->
              <el-descriptions-item label="土地面积" label-align="right" align="center">{{landInfo.landSize}}平方米</el-descriptions-item>
              <el-descriptions-item label="使用权截止日期" label-align="right" align="center">{{landInfo.landDeadLine}}</el-descriptions-item>
              <el-descriptions-item label="土地位置" label-align="right" align="center">{{landInfo.landPosition}}</el-descriptions-item>
              <el-descriptions-item v-if="landInfo.hasGroundBuilding" label="建筑类型" label-align="right" align="center">{{landInfo.buildingType}}</el-descriptions-item>
              <el-descriptions-item v-if="landInfo.hasGroundBuilding" label="建筑面积" label-align="right" align="center">{{landInfo.buildingSize}}平方米</el-descriptions-item>

            </el-descriptions>
          </div>
          <div id="prices" style="padding-top: 5px; padding-left:  5px ;">
            <el-descriptions title="出价记录" :column="1" style="padding-left: 10px;padding-top: 10px"></el-descriptions>
            <el-table
                border
                :data="pricesData"
                style="width: 100%">
              <el-table-column
                  type="index"
                  label="序号"
                  width="150">
              </el-table-column>
              <el-table-column
                  label="出价人"
                  width="200">
                <template #default="scope">
                  {{ getUserName(scope.row.buyerId) }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="biddingPrice"
                  label="出价金额">
                <template #default="scope">
                  {{ scope.row.biddingPrice }}万元
                </template>
              </el-table-column>
              <el-table-column
                  prop="applyTime"
                  label="出价时间">
                <template #default="scope">
                  {{ dateStr(scope.row.applyTime) }}
                </template>
              </el-table-column>
<!--              <el-table-column  prop="applyTime" label="当前状态" v-if="auctionInfo.auctionStatus !== 2">-->
              <el-table-column  prop="applyTime" label="当前状态">
                <template #default="scope">
                  <span style="color:deepskyblue" v-if="scope.$index === 0 ">最高价</span>
                </template>
              </el-table-column>

              <el-table-column
                  label="中标" v-if="auctionInfo.auctionStatus === 99">
                <template #default="scope">
                  <el-tag class="ml-2" type="success" v-if="scope.row.isSelected === 1">中标</el-tag>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="land-info__detail">
            <el-tabs type="border-card" style="margin-left: 20px">
              <el-tab-pane label="重要提示">
                <div v-html="auctionInfo.importantNotice"></div>
              </el-tab-pane>
              <el-tab-pane label="拍卖公告">
                <div v-html="auctionInfo.auctionNotice"></div>
              </el-tab-pane>
              <el-tab-pane label="竞买须知">
                <div v-html="auctionInfo.biddingInstructions"></div>
              </el-tab-pane>
              <el-tab-pane label="标地介绍" >

                <div v-html="auctionInfo.subjectIntroduction"></div>
              </el-tab-pane>
<!--                <el-tab-pane label="竞买人条件">-->
<!--                  {{ landInfo.buyerCondition }}-->
<!--                </el-tab-pane>-->
            </el-tabs>
          </div>
          <div id="img" style="margin-top: 10px">
            <el-descriptions title="土地图片" size="medium" style="padding-left: 10px;padding-top: 10px" :column="1"></el-descriptions>
            <el-row :gutter="20">
              <el-col :offset="1" :span="12" v-for="(item,index) in landPics" :key="index">
                <img :src="item" width="100%"/>
              </el-col>
            </el-row>
          </div>
          <div id="map" style="margin-top: 10px">
            <el-descriptions title="土地地图" :column="1" style="padding-left: 10px;padding-top: 10px;height: 40px"></el-descriptions>
            <Map :landInfo="landInfo"/>
          </div>

        </div>
        <footer2 />
      <AgreementTradeFiles ref="tradeFiles" />
      <FaceRecognition  ref="facerecognitionRef"   />
    </div>
</template>
<script >
import LandHeader from "@/components/nav";
import  footer2 from "@/components/footer.vue";
import timeCounter from "@/components/TimeCounter.vue";
import CommonService, {getidCardInfo} from "@/service/CommonService";
import { useDict,findLabel } from "@/hooks/dict";



const facerecognitionDialog = defineAsyncComponent(() => import('./my/FaceRecognition.vue'));
const facerecognitionRef = ref();

import '@/assets/css/land-info.scss'
import {time} from "echarts";
import {Message, MessageBox} from "element-ui";
import AgreementTradeFiles from "@/components/AuctionTradeFiles.vue";
import FaceRecognition from "./my/FaceRecognition.vue";
import dayjs from "dayjs";
import Map from "@/components/TMap.vue";
import {RouterLink} from "vue-router";
import {defineAsyncComponent, ref} from "vue";

export default {
    name: "LandInfo",
    components: {
      RouterLink,
      Map,
      AgreementTradeFiles,
      FaceRecognition,
        LandHeader,
        footer2,
        timeCounter
    },
    data() {
        return {
            bigimgurl:'',
            timer:null,
            newTimer:null,
            endTimer:null,
            haveFavorite: false,
            timeCounterEnd: 0,
            pricesData :[],
            auctionInfo: {
              transactionStatus:'',
              paimaishiInfo:{
                name:'',
                mobile:'',
                companyName:'',
                certNo:'',
              }
            },
          forms:{
            timernew:null,
            userverify:null,
          },
            speaks: {
              current:0,
              records:[],
            },
            landInfo: {
                id: "",
                title: "",
                landPics: '',
                regionId: '',
                createTime: '',
                price: '',
                landPurpose: '',
                transactionWay: '',
                ownerType: '',
                ownerOrg: '',
                licenseNoType: '',
                importantNotice: '',
                auctionNotice: '',
                biddingInstructions: '',
                subjectIntroduction: '',
                buyerCondition: '',
                licenseNo: '',
                landSize: '',
                landDeadLine: '',
                landPosition: '',
                volumeRate: '',
                geoInfo:[]
            },
        };
    },
    computed: {
      isEnd(){
        let date = new Date()
        let tmp = JSON.parse(JSON.stringify(this.auctionInfo));
        return tmp.auctionEndTime < date.getTime();
      },
      isAuctioning(){
        let date = new Date()
        let tmp = JSON.parse(JSON.stringify(this.auctionInfo));
        // console.log(tmp.auctionStartTime< date.getTime(), tmp.auctionEndTime > date.getTime(),  tmp.transactionStatus !== 'finish');
        return tmp.auctionStartTime < date.getTime() && tmp.auctionEndTime > date.getTime() &&
            tmp.transactionStatus !== 'finish';
      },
      isNormal(){
        let date = new Date()

        let tmp = JSON.parse(JSON.stringify(this.auctionInfo));
        // console.log(tmp.applyStartTime,tmp.applyEndTime,date.getTime());
        return  tmp.applyStartTime < date.getTime() &&  tmp.applyEndTime > date.getTime();
      },
        visible(){
          let date = new Date()
          return this.auctionInfo.auctionEndTime > date.getTime() && this.auctionInfo.transactionStatus !== 'finish';
        },
        haveMap() {
            return this.landInfo.geoInfo && this.landInfo.geoInfo.length > 0;
        },
        landPics() {
            return this.landInfo.landPics ? this.landInfo.landPics.split(",") : [];
        },
        land_purpose() {
            return this.getDict("land_purpose");
        },
      demand_purpose() {
        // console.log(this.getDict("demand_purpose"))
        return this.getDict("demand_purpose");
      },
        transaction_way() {
            return this.getDict("transaction_way");
        },
        land_area() {
            return this.getDict("land_area");
        },
        land_owner_type() {
            return this.getDict("land_owner_type");
        },
        land_license_type() {
            return this.getDict("land_license_type");
        }
    },
    mounted() {
        this.loadLandInfo();
        this.checkFavorite();
    },
    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      if (this.newTimer) {
        clearInterval(this.newTimer);
      }
      if (this.endTimer) {
        clearInterval(this.endTimer);
      }
    },
  methods: {
    getLandPurposeItemName(demand_purpose,id) {
      let land_purposes = useDict(demand_purpose);
      let tmp = '';
      land_purposes[demand_purpose].value.forEach((item) => {
        if (item.value === id) {
          tmp =  item.label;
        }
      })
      return tmp;
    },
      dateStr(date) {
        return dayjs(new Date(parseInt(date))).format("YYYY年MM月DD日 HH时mm分");
      },
      getUserName(userId) {
        return userId === localStorage.getItem('user_id') ? localStorage.getItem('username') :'*****';
      },
      loadPrices(actionId){
        CommonService.getBargainPrices({paimaiId:actionId}).then((res) => {
          if (res.code === 0) {
            this.pricesData = res.data.records;
          }
        });
      },
      async bidPrice(){
        if(!CommonService.isLogin()){
          this.$message.error("请先登录");
          return;
        }
        // console.log(this.auctionInfo.id)
        let minPrice = parseInt((this.auctionInfo.currentPrice?? this.auctionInfo.startPrice)) + parseInt(this.auctionInfo.rise)
        this.$prompt('请输入竞拍价格且需不小于 ' + minPrice + ' (单位:万元)', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[0-9.]*$/,
          inputErrorMessage: '请输入正确的价格'
        }).then(({ value }) => {


          if (value < minPrice) {
            Message.error('请输入竞拍价格且需不小于 ' + minPrice + ' (单位:万元)');
            return;
          }
          let tmp = (value - parseInt(this.auctionInfo.startPrice)) / this.auctionInfo.rise;
          if (!Number.isInteger(tmp)) {
              Message.error('出价需为加价幅度整数倍');
            return;
          }
          CommonService.addBargain({
            paimaiId: this.auctionInfo.id,
            biddingPrice: value,
          }).then((res) => {
            if (res.code === 0) {
              this.auctionInfo.currentPrice = value;
              Message.success('出价成功');
            } else {
              Message.error(res.msg);
            }
            window.location.reload();
          }).catch((err) => {
                Message.error(err.message);
              });
        })
      }
    ,lunxunData ()  {
    if( this.forms.timernew ==null)
    {
      this.forms.timernew = window.setInterval(() => {
        setTimeout(() => {
          this.checkQrCode()
        }, 0)
      }, 3000)

    }

  },
    async  checkQrCode()  {
      const res = await getidCardInfo();
      // window.console.log(res)
      if (res.data.checked === 1) {
        // 803表示登录成功，以下做登录成功后的事件
        this.forms.userverify=true;
        this.stop()

      } else if (res.data.code === 800) {
        // 803表示二维码过期
        window.console.log('二维码过期')
        // 开启css覆盖当前二维码
        // 停止轮询
        this.stop()
      }
    },
    stop(){
      window.clearInterval(this.forms.timernew)
      clearInterval(this.forms.timernew);
      this.forms.timernew = null;
      window.location.reload();
    },
  applyAuction(){
        if(!CommonService.isLogin()){
          this.$message.error("请先登录");
          return;
        }
        CommonService.getUserInfo().then((re)=>{
          console.log(re.data.sysUser.name)
          if(re.data.sysUser.name==''  || re.data.sysUser.name==null)
          {
            this.lunxunData();
            this.$refs.facerecognitionRef.openDialog('','', (data) => {
              stop();
            });
          }else {
            this.$refs.tradeFiles.openDialog({id:this.auctionInfo.id},'auction');
          }

        });

      },
      exhibitionimg(event) {
        // console.log(event.target.src);
        this.bigimgurl = event.target.src.slice(0,-6) ;
        // console.log(event.target.src.slice(0,-3) + '600')
      },
        addFavorite() {
            if(!CommonService.isLogin()){
                this.$message.error("请先登录");
                return;
            }

            if(this.haveFavorite){
                CommonService.delFavorite(this.landInfo.id,"supply").then((res) => {
                    if (res.code === 0) {
                        this.haveFavorite = false;
                        this.$message.success("取消收藏成功");
                    }
                });
            }else{
                CommonService.addFavorite(this.landInfo.id,"supply").then((res) => {
                    if (res.code === 0) {
                        this.haveFavorite = true;
                        this.$message.success("收藏成功");
                    }
                });
            }
        },
        checkFavorite() {
            if(!CommonService.isLogin()){
                return;
            }

            CommonService.checkFavorite(this.$route.params.id,"supply").then((res) => {
                if (res.code === 0) {
                    this.haveFavorite = res.data;
                }
            });
        },
        loadLandInfo() {

            CommonService.getLandDetailWithAuction(this.$route.params.id).then((res) => {
                if (res.code === 0) {
                  // console.log(res)
                    Object.assign(this.landInfo,res.data.landInfo);
                    Object.assign(this.auctionInfo ,res.data);
                    this.loadPrices(res.data.id);

                    CommonService.getAuctionSpeaks(res.data.id).then((SpeaksRes) =>{
                      if (SpeaksRes.code === 0) {
                        Object.assign(this.speaks, SpeaksRes.data);
                      }
                    } );
                    if (res.data.paimaishiInfo) {
                      this.timer = setInterval(() => {
                        CommonService.getAuctionSpeaks(res.data.id).then((SpeaksRes) =>{
                          if (SpeaksRes.code === 0) {
                            Object.assign(this.speaks, SpeaksRes.data);
                          }
                        } )
                      }, 5000);
                    }
                  if (this.isAuctioning) {
                    this.timer = setInterval(() => {
                      CommonService.getBargainPrices({paimaiId:res.data.id}).then((priceRes) =>{
                        if (priceRes.code === 0 && priceRes.data.records.length > 0) {
                          this.auctionInfo.currentPrice = priceRes.data.records[0].biddingPrice;
                          this.pricesData = priceRes.data.records;
                        }
                      });
                      let date = new Date().getTime();
                      if (this.auctionInfo.auctionEndTime < date) {
                        this.auctionInfo.biddingStatus = 2;
                      }
                    }, 5000)
                  }
                  if (this.isNormal) {
                    this.newTimer = setInterval(() => {
                      let date = new Date().getTime();
                      if (this.auctionInfo.applyEndTime < date) {
                        this.auctionInfo.biddingStatus = 2;
                      }
                    }, 5000)
                  }
                  if (this.isAuctioning) {
                    this.endTimer = setInterval(() => {
                      let date = new Date().getTime();
                      if ( date - this.auctionInfo.auctionEndTime < 5000 ) {
                        window.location.reload();
                      }
                    }, 15000)
                  }
                }

            });
        },
        getDict(dictName) {
            return useDict(dictName)[dictName].value;
        },
        findLabel,
    },
};  
</script>

<style lang="scss" scoped>
.exhibitionimg {
  flex: 3;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  .toplargebox {
    flex: 3;
    width: 470px;
    height: 350px;
    overflow: hidden;
    .bigimg {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .bottombox {
    margin: 5px 0;
    flex: 1;
    ul {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: left;
      padding: 5px 0;
      box-sizing: border-box;
      li {
        position: relative;
        text-align:center;
        width: 20%;
        height: 100%;
        margin: 0 5px;
        overflow: hidden;
        img {
          display:inline-block;  /*让图片变为行内块*/
          vertical-align:middle;
          cursor: pointer;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

</style>
<style>
.my-content{
  margin-left: 5px;
}
</style>
